import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import { Blamable }             from 'helpers/traits';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.staticLabel('Typologie')
@model.urnResource('ape_code')
@doc.path('/ape_codes/{?id}')
export default class ApeCodeModel extends Blamable(Timestampable(DirectoryPrivateApiModel)) {
	public _filters: ModelFiltersExtended<{
		'code': id;
	}> = {};

	public _sorts: ModelSortsExtended<{
		'code': string;
		'id': string;
	}> = {};

	@doc.string declare code: string;
	@doc.string declare label: string;
}
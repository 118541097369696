/* eslint-disable @typescript-eslint/ban-ts-comment */
import ApiCollection    from 'Collections/ApiCollection';
import AbstractApiModel from 'modelx/models/abstracts/AbstractApiModel';
import { whenAsync }    from 'tools/modelxTools';

class ModelReferenceManager {
	private _data: Record<string, ApiCollection<never>> = {};

	public clear() {
		this._data = {};
	}

	public collection<T extends typeof AbstractApiModel>(modelClass: T): ApiCollection<T['prototype']> {
		if (!this._data[modelClass.path]) {
			this._data[modelClass.path] = new ApiCollection(modelClass as never);
			this._data[modelClass.path].sortBy('id').list().then(() => null);
		}

		return this._data[modelClass.path];
	}

	public id<T extends typeof AbstractApiModel>(
		modelClass: T,
		// @ts-ignore
		reference: T['prototype']['reference'] | (T['prototype']['reference'])[],
	) {
		return Array.isArray(reference) ?
			reference.map(r => this.property(modelClass, r, 'id')) :
			this.property(modelClass, reference, 'id');
	}

	public label<T extends typeof AbstractApiModel>(
		modelClass: T,
		// @ts-ignore
		reference: T['prototype']['reference'],
	) {
		return this.property(modelClass, reference, 'modelLabel').toLowerCase();
	}

	public model<T extends typeof AbstractApiModel>(
		modelClass: T,
		// @ts-ignore
		reference: T['prototype']['reference'],
	): T['prototype'] {
		// @ts-ignore
		return this.collection(modelClass).find(m => m['reference'] === reference) || new modelClass();
	}

	public async modelAsync<T extends typeof AbstractApiModel>(
		modelClass: T,
		// @ts-ignore
		reference: T['prototype']['reference'],
	) {
		await whenAsync(() => this.collection(modelClass).isLoaded);
		const model = this.model(modelClass, reference);

		if (!model.id) {
			throw Error(`La référence "${reference}" n'a pas été trouvée.`);
		}

		return model;
	}

	public property<T extends typeof AbstractApiModel>(
		modelClass: T,
		// @ts-ignore
		reference: T['prototype']['reference'],
		property: 'modelLabel' | 'id' | 'iri' | 'urn' = 'id',
	) {
		const model = this.model(modelClass, reference);

		return model ? model[property].toString() : '';
	}

	public urn<T extends typeof AbstractApiModel>(
		modelClass: T,
		// @ts-ignore
		reference: T['prototype']['reference'] | (T['prototype']['reference'])[],
	) {
		return Array.isArray(reference) ?
			reference.map(r => this.property(modelClass, r, 'urn')) :
			this.property(modelClass, reference, 'urn');
	}
}

export default new ModelReferenceManager();
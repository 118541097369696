import lazyWithRetry from 'tools/lazyWithRetry';

const CommercialOffersPage = lazyWithRetry(() => import(
	/* webpackChunkName: "commercialOffersPage" */
	'../../pages/CommercialOffers/CommercialOffersPage'));

export default [
	{
		component: CommercialOffersPage,
		exact: true,
		path: '/commercial_offers',
	},
];
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Model }              from '@mathquis/modelx';
import { LoggedApiConnector } from 'Connectors/LoggedApiConnector';
import moment                 from 'moment';
import ConfigProxy            from 'tools/ConfigProxy';

const DEFAULT_FILTER_KEYS = ['timestamp', 'service'] as const;

export type Operator = 'eq' | 'neq' | 'exists' | 'nexists' | 'strictly_before' | 'before' |
	'strictly_after' | 'after' | 'gt' | 'gte' | 'lt' | 'lte';

type FilterKey = `${typeof DEFAULT_FILTER_KEYS[number]}[${Operator}]`;

export const analyticsConnector = new LoggedApiConnector({
	baseURL: ConfigProxy.getServiceConfig('analytics', 'api_endpoint'),
});

export default class AnalyticsApiModel extends Model {
	static get connector() {
		return analyticsConnector;
	}

	public static get path() {
		return '/stonecode_analytics_metrics';
	}

	protected _dimensions: Record<string, string | string[]> = {};

	protected _filters = {} as Record<FilterKey, string>;


	public fetch(options: any = {}) {
		return super.fetch({ ...options, params: { ...options.params, ...this._filters, ...this._dimensions } });
	}

	public resetDimensions() {
		this._dimensions = {};
		return this;
	}

	public setDate(date = moment(), operator: Operator = 'eq') {
		return this._setFilter('timestamp', date.format(), operator);
	}

	public setDateAfter(date = moment().startOf('day')) {
		return this.setDate(date, 'gt');
	}

	public setDimension(key: string, value: string | string[], operator: Operator = 'eq') {
		this._dimensions[`dimensions.${key}[${operator}]`] = value;
		return this;
	}

	public setService(service: string, operator: Operator = 'eq') {
		return this._setFilter('service', service, operator);
	}

	protected _setFilter(key: typeof DEFAULT_FILTER_KEYS[number], value: string, operator: Operator = 'eq') {
		this._filters[`${key}[${operator}]`] = value;
		return this;
	}
}

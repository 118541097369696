import doc                      from 'decorators/doc';
import model                    from 'decorators/model';
import { Timestampable }        from 'helpers/traits';
import DirectoryPrivateApiModel from 'modelx/models/abstracts/DirectoryPrivateApiModel';

@model.cacheDuration()
@model.staticLabel('GlobalContactStatus')
@model.urnResource('global_contact_status')
@doc.path('/global_contact_statuses/{?id}')
export default class GlobalContactStatusModel extends Timestampable(DirectoryPrivateApiModel) {
	public _filters: ModelFiltersExtended<{
		'reference': DirectoryGlobalContactStatusReference;
	}> = {};

	public _sorts: ModelSortsExtended<unknown> = {};

	@doc.string declare label: string;
	@doc.string declare reference: DirectoryGlobalContactStatusReference;

	get color(){
		switch (this.reference) {
			case 'disabled':
				return 'red';
			case 'enabled':
				return 'green';
		}
	}
}